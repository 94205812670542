import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import { SEO } from '../components/atoms/seo'

const pageMeta = {
  title: 'Portfolio',
  type: 'portfolio',
  description: 'Lou Groshek software engineer portfolio',
  keywords: `lou groshek, lou, groshek, software engineer, web, development, developer, portfolio`,
}

const PortfolioPage = ({ location }) => {
  return (
    <Layout location={pageMeta.location} pageType={pageMeta.type}>
      <Row className="heading">
        <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }}>
          <h1>{pageMeta.title}</h1>
        </Col>
      </Row>
      <Row className="heading">
        <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }}>
          <h2>Recent NDA Projects</h2>
          <p>
            As a technology consultant I have been working on the following NDA
            projects:
          </p>
          <ul>
            <li>
              React.js interface for a cranial and spinal surgery device, using
              Typescript, RTK, Material UI, WebSockets, and Electron
            </li>
            <li>
              Next.js app for SSR and CSR eCommerce flow, using Typescript and
              Apollo Client
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Diversity Data Kids National Child Opportunity Map</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            This map allows users to explore indicators of child opportunity
            across the United States, with different norming levels for
            national, state, and metro areas. Features include:
          </p>
          <ul>
            <li>
              Mapbox map with dynamic, filtered GeoJSON features and dynamic
              demographic layers
            </li>
            <li>
              <a href="https://github.com/Hyperobjekt/ddk-etl" target="_blank">
                Data processing pipeline
              </a>
              to convert
              <a href="https://github.com/Hyperobjekt/ddk-data" target="_blank">
                client data sets
              </a>
              into Mapbox tilesets
            </li>
            <li>
              Hash routing with localStorage to manage and cache map state
            </li>
            <li>
              Hasy-driven embed state for embedding in other web platforms
            </li>
            <li>Responsive, device-targeted interactions and display</li>
          </ul>
          <p>
            Skills: React.js, MapBox, GeoJSON, SCSS, Material UI, Node.js, nwb,
            npm, python, pandas, Docker
          </p>
          <Link
            to="https://diversitydatakids.org/maps/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Diversity Data Kids National Child Opportunity Map"
          >
            Go to DDK Map
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage alt="Diversity Data Kids Map" src="../images/ddk.png" />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>University of North Texas Social Mobility Hub Map</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            This map allows users to explore indicators of social mobility in
            the North Texas region. Project features include:
          </p>
          <ul>
            <li>
              Mapbox map with dynamic, filtered GeoJSON features and dynamic
              demographic layers
            </li>
            <li>
              Loads
              <a
                href="https://github.com/childpovertyactionlab/Social-Mobility/blob/main/.github/workflows/sync-data.yml"
                target="_blank"
              >
                remote, gzipped geojson files
              </a>
              to improve map performance
            </li>
            <li>
              Hash routing with localStorage to manage and cache map state
            </li>
            <li>User tour with Joyride</li>
            <li>Responsive, device-targeted interactions and display</li>
          </ul>
          <p>
            Skills: React.js, MapBox, GeoJSON, SCSS, Bootstrap, nwb, npm, Gatsby
          </p>
          <Link
            to="https://socialmobility.netlify.app/explorer/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Go to UNTX Map
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            alt="UNTD Social Mobility Explorer"
            src="../images/untd.png"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Child Poverty Action Lab Explorer Map</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            This map allows users to explore indicators of child opportunity
            within a school zones, a 2-mile radius around a school, for all
            schools the Dallas ISD school district. Users can visualize
            indicators in relation to the distribution of racial and ethnic
            groups. The project encourages equitable distribution of school bond
            resources across the district. Project features include:
          </p>
          <ul>
            <li>
              Mapbox map with dynamic, filtered GeoJSON features and dynamic
              demographic layers
            </li>
            <li>eCharts charting for feeder school data and patterns</li>
            <li>Turf.js to dynamically generate school "zones"</li>
            <li>
              Hash routing with localStorage to manage and cache map state
            </li>
            <li>User tour with Joyride</li>
            <li>Responsive, device-targeted interactions and display</li>
          </ul>
          <p>Featured in:</p>
          <ul>
            <li>
              <a href="https://www.dmagazine.com/publications/d-magazine/2020/august/dallas-isds-fight-to-fix-the-most-racist-map-of-dallas/">
                D Magazine, August 2020
              </a>
            </li>
            <li>
              <a href="https://www.dmagazine.com/frontburner/2020/10/the-most-interesting-part-of-dallas-isds-bond-is-one-of-its-smallest/">
                D Magazine, October 2020
              </a>
            </li>
          </ul>
          <p></p>
          <p>
            Skills: React.js, MapBox, GeoJSON, SCSS, Bootstrap, nwb, npm, Gatsby
          </p>
          <Link
            to="https://dallasisd.resourceexplorer.org/explorer/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Child Poverty Action Lab Explorer"
          >
            Go to CPAL Explorer
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            alt="Child Poverty Action Lab Explorer"
            src="../images/cpal-600.png"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Eviction Lab Covid-19 Policy Scorecard</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            This site feature ranks all US states by COVID-19-related tenant
            protections (or lack thereof). This interface and the individual
            state details pages all fetch and render data from a Google
            spreadsheet of eviction policy data maintained by Eviction Lab.
            Featured in:
          </p>
          <ul>
            <li>
              <a href="https://s3.amazonaws.com/public-inspection.federalregister.gov/2020-19654.pdf">
                CDC Eviction Moratorium (cites Scorecard)
              </a>
            </li>
            <li>
              <a href="https://www.nytimes.com/2020/09/01/business/eviction-moratorium-order.html">
                The New York Times, September 1, 2020
              </a>
            </li>
            <li>
              <a href="https://www.nytimes.com/2020/08/07/business/economy/housing-economy-eviction-renters.html">
                The New York Times, August 7, 2020
              </a>
            </li>
            <li>
              <a href="https://www.nytimes.com/2020/07/04/us/coronavirus-evictions-renters-immigrants.html">
                The New York Times, July 4, 2020
              </a>
            </li>
            <li>
              Hasan Manaj's <i>Patriot Act</i>, (
              <a href="https://www.youtube.com/watch?v=MPFPBzr7FgY&amp;feature=youtu.be">
                episode
              </a>
              , <a href="https://www.dontgetkickedout.com">companion site</a>)
            </li>
            <li>
              <a href="https://abcnews.go.com/US/mass-evictions-horizon-us-confronts-coronavirus-housing-crisis/story?id=70367541">
                ABC News
              </a>
            </li>
            <li>
              <a href="https://www.npr.org/2020/05/01/848247228/rent-is-due-today-but-millions-of-americans-wont-be-paying">
                NPR
              </a>
            </li>
            <li>
              <a href="https://www.washingtonpost.com/nation/2020/04/29/which-states-are-doing-better-job-protecting-renters-being-evicted-during-coronavirus-pandemic/?arc404=true">
                The Washington Post
              </a>
            </li>
          </ul>
          <p>
            Skills: jQuery, Bootstrap, Google Sheets API, mobile-first, a11y
          </p>
          <Link
            to="https://evictionlab.org/covid-policy-scorecard/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Eviction Lab covid-19 housing policy scorecard"
          >
            Go to Policy Scorecard
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            alt="Child Poverty Action Lab Explorer"
            src="../images/evictionlab-covid-scorecard-02.png"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Eviction Lab COVID-19 Housing Policy List</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            A precursor to the Policy Scorecard, this dynamic table loads a list
            of national and local Covid-10 eviction policies. Project features
            include:
          </p>
          <ul>
            <li>Sortable table draws content from Google Spreadsheet</li>
          </ul>
          <p>Featured in:</p>
          <ul>
            <li>
              <a href="https://www.nytimes.com/2020/07/11/your-money/coronavirus-eviction-prevention-renters-landlord.html?searchResultPosition=1">
                The New York Times
              </a>
            </li>
          </ul>
          <p></p>
          <p>Skills: JavaScript, jQuery, AJAX</p>
          <Link
            to="https://evictionlab.org/covid-eviction-policies/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Eviction Lab policy list"
          >
            Go to Policy List
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            alt="Evictionlab Covid-19 policy list"
            src="../images/evictionlab-policy-list.png"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Eviction Lab Custom PDF and PPTX Reports</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>Project features include:</p>
          <ul>
            <li>
              Update PDF and PPT generation scripts to render confidence
              interval
            </li>
            <li>Combine Handlebars templates for more reliable translation</li>
            <li>
              Update Eviction Lab map to pass confidence interval in request for
              PDF and PPT exports
            </li>
          </ul>
          <p>
            Skills: Angular, Handlebars, PptxGenJS, Headless Chrome, Typescript
          </p>
          <Link
            to="https://evictionlab.org/map/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Eviction Lab policy list"
          >
            Access on EvictionLab Map
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/eviction-lab-export.png"
            alt="Evictionlab PDF and PPTX export contents"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Graph and Table Updates for Eviction Lab Map</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>Project features include:</p>
          <ul>
            <li>
              Calculate and render confidence interval for line and bar chart
              data
            </li>
            <li>Add confidence interval to chart tooltips</li>
            <li>Add confidence interval to location cards and tables</li>
            <li>Update Google Analytics tracking data</li>
            <li>Add confidence interval toggle controls</li>
            <li>
              Create SVG patterns for bar chart confidence interval shading
            </li>
          </ul>
          <p>Skills: Angular, d3, Typescript, npm</p>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/eviction-lab-ci.png"
            alt="Educational Opportunity Project site"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Eviction Lab Hugo Site</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>Project features include:</p>
          <ul>
            <li>
              Convert HUGO site from gulp build process to native Hugo build
            </li>
            <li>
              Update image processing from gulp to native Hugo image processing
            </li>
          </ul>
          <p>Skills: HUGO, Golang, gulp</p>
          <Link
            to="https://evictionlab.org/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Eviction Lab policy list"
          >
            Go to EvictionLab
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/evictionlab-hugo.jpg"
            alt="Evictionlab site"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Stanford Educational Opportunity Project PDF Export</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            This project generates custom PDF reports based on locations
            selected by the user. The report is generated by rendering a
            Handlebars template in headless Chrome, then printing it to a PDF
            data stream for download.
          </p>
          <p>
            Skills: Puppeteer, Handlebars, Headless Chrome, node.js, scss, AWS
          </p>
          <Link
            to="https://edopportunity.org/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Explore SEDA Map
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/seda-pdf-export.png"
            alt="SEDA PDF Export"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>The Educational Opportunity Project at Stanford University</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>Project features include:</p>
          <ul>
            <li>HUGO site architecture and styling</li>
            <li>eCharts development and configuration</li>
            <li>React.js component updates</li>
            <li>SVG animation</li>
            <li>jQuery modal dialogs</li>
            <li>
              Netlify Lambda function, using node.js to register users to
              MailChimp list
            </li>
            <li>
              Google Analytics custom events and tracking with Google Tag
              Manager
            </li>
          </ul>
          <p>
            Skills: HUGO, eCharts, Golang, Bootstrap, React.js, nodejs, Netlify
            Functions, Netlify CMS, Google Analytics, Google Tag Manager
          </p>
          <Link
            to="https://edopportunity.org/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Go to Seda Site
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/seda-site.png"
            alt="Educational Opportunity Project site"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Dignity + Debt React App</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            This project features a custom React.js component allows users to
            compare their student loan debt to that of various US demographics.
            It also allows users to explore how loan refinancing would affect
            their payoff timeline and overall payment on a loan.
          </p>
          <p>Skills: React.js, HUGO, Golang, Bootstrap, Netlify</p>
          <Link
            to="https://www.dignityanddebt.org/projects/student-debt-calculator/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Go to Debt Calculator
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/loan-debt-spiral.png"
            alt="Dignity + Debt Site react app"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Twitter Feed for TeachingLab HUGO Site</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            TeachingLab wanted to display recent tweets on their site. I wrote
            JavaScript and a nodejs Netlify Function to fetch the tweets through
            Twitter's oAuth API, render the tweets, and animate them.
          </p>
          <p>
            Skills: HUGO, Twitter API 2.0, Golang, Bootstrap, React.js, nodejs,
            Netlify Functions, Netlify CMS
          </p>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/teachinglab-twitter.png"
            alt="TeachingLab web site with twitter feed"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Bespoke PrimedIO Static Site with HUGO</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            PrimedIO wanted a static site with blazing-fast SEO. They needed
            internationalization, Nelify Forms, and were capable of editing blog
            posts and other content themselves once the site was up and running.
            This site features extensive CSS work to implement design elements
            and responsiveness without negatively affecting load time.
          </p>
          <p>
            Skills: HUGO, Golang, CSS, Bootstrap, JavaScript, PHP, JSON,
            Netlify, Netlify Forms
          </p>
          <Link
            to="https://primed.io"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Go to PrimedIO
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage src="../images/primedio.png" alt="PrimedIO Site" />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Custom Corporate Traning SCORM with Adapt</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            This corporate client needed SCORM online learning content that
            would be responsive, WCAG A compliant, and easily translated into
            several langauges. They selected a subway map theme for content
            organization, and the content pages reflect this theme by portraying
            color-coded subway "paths" and subway stop "nodes". I developed
            several custom Adapt plugins in order to give the client the highly
            customized format they preferred. These Adapt plugin customizations
            include:
          </p>
          <ul>
            <li>A custom question type</li>
            <li>
              A custom plugin presenting a responsive dialogue between two
              characters
            </li>
            <li>A custom "more info" text feature</li>
            <li>
              Customizations to the hotspotMenu plugin to add graphical progress
              display overlays on the subway map
            </li>
            <li>Custom SCORM reporting</li>
            <li>Custom navigation locking</li>
            <li>
              Schema updates for full translation of the certificate and several
              other components
            </li>
          </ul>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/adapt-corporate-training.png"
            alt="Adapt corporate training SCORM"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Princeton University's Dignity + Debt HUGO Site</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            This site uses Bootstrap 4 for navigation and layout responsiveness.
            Content pages loop through custom data files stored outside of the
            HUGO content directory. Many content fields are editable using
            Netlify CMS.
          </p>
          <p>
            Skills: HUGO, Golang, CSS, JavaScript, PHP, JSON, Netlify, Netlify
            CMS
          </p>
          <Link
            to="https://dignityanddebt.org"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Go to Dignity + Debt
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/dignity-and-debt.png"
            alt="Dignity + Debt Site"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>
            User1st Accessibility Remediation Documentation Site with HUGO
          </h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            User1st wanted to use a static site generator to create a
            documentation reference for their automated remediation tool and
            developer training programs. This site uses a modified version of
            the DocDock theme. I created a custom template for the rule
            documentation pages, and wrote a PHP script to convert CSVs of rule
            documentation into individual markdown files.
          </p>
          <p>
            In subsequent updates to this site, I have added a Netlify CMS
            schema so that a11y criteria are editable via Netlify CMS. I have
            also added JSON custom content types to output all a11y standards,
            standards sorted by WCAG guideline, and standards sorted by tag. I
            have also retooled the navigation menu to sort all items by WCAG
            Guideline.
          </p>
          <p>
            Skills: HUGO, Golang, CSS, JavaScript, PHP, JSON, Netlify, Netlify
            CMS, a11y
          </p>
          <Link
            to="https://docs.wcag.online"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Go to User1st Docs
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/user1st-docs.png"
            alt="User1st accessibility rule documentation site"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>LaurelYoga Online Learning Marketplace with LearnDash</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            Laurel needed a robust online promotion, scheduling, and marketplace
            solution. The theme Laurel had selected required a custom child
            theme for additional styling and course enrollment functionality. We
            did development in stages, using separate production and test
            environments to handle code pulls and site upgrades. Laurel now
            earns a significant passive income selling video courses with the
            WordPress CMS, combined with WooCommerce and LearnDash plugins.
          </p>
          <p>Skills: Wordpress, LearnDash, WooCommerce, PHP, CSS, JavaScript</p>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/laurelyoga.png"
            alt="Laurel's Yoga Site"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>User1st Online Accessibility Training Marketplace with Moodle</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            User1st wanted an online learning environment for the delivery of
            accessible SCORM training modules. The content we produced needed to
            meet the exacting accessibility standards advised in the training
            they contained. We chose the Adapt framework, and when it came time
            to test the SCORM content for accessibility, I was able to use CSS
            and JavaScript to alter the Adapt theme and plugins in order to
            improve screen reader accessibility.
          </p>
          <p>Skills: Moodle, Adapt, SCORM, PHP, CSS, JavaScript</p>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/user1st.png"
            alt="User1st online learning site"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>SumLogix Online Moodle Course Marketplace with Edwiser</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            SumLogix came to me with a legacy Moodle site and several existing
            Moodle courses. Their existing WordPress/Moodle integration was no
            longer functioning, and their Moodle site was long outdated. I
            manually upgraded the Moodle database through several major
            versions, updated the WordPress plugins and theme, added a child
            theme for UX updates, and tested and configured PayPal and Stripe
            purchase workflows. This site accepts transactions in multiple
            currencies, using the WordPress Currency Switcher plugin and
            geolocation to provide the customer with the appropriate currency.
          </p>
          <p>
            Skills: Moodle, WordPress, WooCommerce, Edwiser, PHP, CSS,
            JavaScript
          </p>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage
            src="../images/sumlogix.png"
            alt="SumLogix online learning site"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-desc" xs={{ size: 10, offset: 1 }}>
          <h3>Moodle Core Contributions</h3>
        </Col>
        <Col
          className="col-desc"
          xs={{ size: 10, offset: 1, order: 3 }}
          md={{ size: 5, offset: 1, order: 2 }}
        >
          <p>
            I got my start in web development with a Moodle service provider
            called Remote-Learner, now part of{' '}
            <a
              href="https://learningpool.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learning Pool
            </a>
            . I have made
            <a href="https://moodle.org/dev/gitcommits.php?version=x.x.x&userid=159280&merges=0">
              13 contributions
            </a>
            to Moodle core.
            <a href="https://tracker.moodle.org/browse/MDL-57785">MDL-57785</a>
            reduces the server load resulting from SCORM content access by
            eliminating a navigation menu refresh when the navigation menu is
            not shown in the display.
            <a href="https://tracker.moodle.org/browse/MDL-60478">MDL-60478</a>
            improves performance of the network connectivity check performed
            during SCORM sessions, increasing the interval at which checks were
            performed, increasing the request timeout value to prevent false
            positives, and reducing the default number of alerts shown to 1.
          </p>
          <p>Skills: PHP, YUI, JavaScript, Grunt, git, Travis, SCORM</p>
          <Link
            to="https://moodle.org/dev/gitcommits.php?version=x.x.x&userid=159280&merges=0"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            See My Contributions
          </Link>
        </Col>
        <Col
          className="col-image align-items-center"
          xs={{ size: 10, offset: 1, order: 2 }}
          md={{ size: 5, offset: 0, order: 3 }}
          lg={{ size: 5, order: 3 }}
        >
          <StaticImage src="../images/mdl_tracker.png" alt="Moodle tracker" />
        </Col>
      </Row>
    </Layout>
  )
}

export default PortfolioPage

export const Head = () => <SEO meta={{ ...pageMeta }} />
